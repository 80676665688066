import * as _ from 'modules/util'
import * as R from 'ramda'

export const parseErrorMessage = ({ error, name = '' } = {}) => {
  const method = error?.cause?.request?.method
  const response = error?.cause?.response
  if (!response) return

  const { status } = response
  const { message, errors: errorsIn = {} } = response.body || {}

  const errors = Object.entries(errorsIn)
    .map(([key, value]) => `${key.replace(/_/g, ' ')} ${value}`)
    .join(', ')

  const operationName = _.thru(
    method,
    R.toLower,
    R.prop(R.__, { delete: 'delete', get: 'get', patch: 'update', post: 'save', put: 'update' })
  )

  const errorMessage = _.cond(
    [
      status === 404,
      `Uh oh! We could not ${operationName} that ${name} because it does not exist.`,
    ],
    [
      status === 422,
      `Uh oh! We could not save that ${name}${message ? ` because ${message.toLowerCase()}` : ''}.${
        errors ? ` You need to fix the following errors: ${errors}.` : ''
      }`,
    ],
    [genericFailedMessage]
  )

  return errorMessage
}

const genericFailedMessage = `Uh oh! Something went wrong. ${
  window.navigator.onLine
    ? 'Can you try that again?'
    : 'Check your internet connection and try again.'
}`
