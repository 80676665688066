import { includes } from 'lodash'

export default () =>
  $.ajaxSetup({
    statusCode: {
      401: () => {
        if (includes(['/users/sign_in'], ['/users/unlock/new'], document.location.pathname)) {
          document.location.href = '/users/sign_in'
        }
      },
      403: (e) => {
        if (e.responseText === 'Please enable 2 factor authentication.') {
          document.location.href = '/user/two_factor_authentication/new'
        }
      },
      429: () => {
        document.location.href = '/429'
      }
    }
  })
