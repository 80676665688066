require('core-js/stable')
require('regenerator-runtime/runtime')

// vendor - start

window.jQuery = require('jquery/dist/jquery');
window.$ = window.jQuery;
require('vendor/assets/javascripts/jquery-ui-1.12.1.min');
require('jquery-ujs/src/rails');
require('vendor/assets/javascripts/jquery_plugins/jquery.weekcalendar');
require('vendor/assets/javascripts/jquery.serialize');
require('vendor/assets/javascripts/jquery.deserialize');
window.autosize = require('autosize'); // remove once dashboard is converted to react
require('vendor/assets/javascripts/date');
require('vendor/assets/javascripts/select2');
require('vendor/assets/javascripts/parsley');
require('pickadate/lib/picker.date');
window.moment = require('moment-timezone');

// vendor - end

require('focus-visible')

function requireAll (requireContext) {
  return requireContext.keys().map(requireContext)
}

require('lodash/lodash')
require('respimage/respimage')
window.smoothScroll = require('smoothscroll')

requireAll(require.context('app/assets/javascripts/shared', true, /^\.\/.*\.(coffee|js)$/))
requireAll(require.context('app/assets/javascripts/utilities', true, /^\.\/.*\.(coffee|js)$/))

require('app/assets/javascripts/modules/third-party/honeybadger')
require('app/assets/javascripts/angular/public/app')
require('app/assets/javascripts/angular/public/globals')
require('app/assets/javascripts/angular/shared/components/icon')
require('app/assets/javascripts/angular/shared/services/rg-flash')
require('app/assets/javascripts/angular/cliniko/directives/attributes/rg-button-loads')
require('app/assets/javascripts/shared/stop-rg-button-loads')
require('app/assets/javascripts/angular/shared/directives/loader-dots')
require('app/assets/javascripts/angular/public/cookie-consent')
