import angular from 'angular'
import jqueryAjaxSetup from 'app/assets/javascripts/modules/app-setup/jquery-ajax-setup'
import ngAnimate from 'angular-animate'

import 'vendor/assets/javascripts/ng-rails-csrf'

window.cliniko = angular.module 'public', [ngAnimate, 'ng-rails-csrf']
.config ($animateProvider, $interpolateProvider, $qProvider) ->
  # Restrict animation to elements with the angular-animate css class.
  $animateProvider.classNameFilter(/angular-animate/)
  $qProvider.errorOnUnhandledRejections(window.environment == 'development')
  $interpolateProvider.startSymbol('|[|').endSymbol('|]|')

.run ->
  jqueryAjaxSetup()
