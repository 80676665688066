import deleteAllCookiesFactory from 'delete-all-cookies'
import enableGoogleTagManager from 'app/assets/javascripts/modules/utilities/enable-google-tag-manager'
import { get as getCookieValue, set as setCookie } from 'cookies-js'
import html from './cookie-consent.html'

window.cliniko.component 'cookieConsent',
  bindings: {}
  template: html
  controller: ($element, $scope) ->
    COOKIE_POLICY_VERSION = 1

    cookieName = 'localDataConsent'
    deleteAllCookies = deleteAllCookiesFactory window
    domain =
      switch window.environment
        when 'development', 'staging', 'test' then window.hostname
        else 'cliniko.com'

    @$onInit = ->
      cookieValue = getCookieValue cookieName
      if cookieValue == "#{COOKIE_POLICY_VERSION}-true"
        enableGoogleTagManager()
        destroyComponent()
      else
        deleteAllCookies()
        if cookieValue == "#{COOKIE_POLICY_VERSION}-false"
          @cookieConsentRejected()
        else
          @shouldShowConsentDialog = true

    @cookieConsentGranted = ->
      enableGoogleTagManager()
      setCookie cookieName, "#{COOKIE_POLICY_VERSION}-true", domain: domain, expires: moment().add(1, 'year').toDate()
      destroyComponent()

    @cookieConsentRejected = ->
      setCookie cookieName, "#{COOKIE_POLICY_VERSION}-false", domain: domain
      destroyComponent()

    destroyComponent = ->
      $scope.$destroy()
      $element.remove()

    return
